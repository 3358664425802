body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  /*background-color: rgba(199, 138, 102, 0.25); /* Light brown extracted from the background */
  /*background:linear-gradient(135deg, rgba(214, 125, 70, 0.8), rgba(58, 142, 141, 0.8)); /* Orange to Teal gradient with opacity */
  /*background-blend-mode: multiply; /* To darken the gradient when overlapped */
  font-family: 'Arial', sans-serif;
  /*background: linear-gradient(135deg, #f3f3f3, #e8e8e8);*/

  color: #333;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  
  /*background: linear-gradient(135deg, #f3f3f3, #e8e8e8);*/

/*
.container {
  z-index: 10;
  position: relative;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: To add a background to your main content */
  /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); *//* Optional: To give a slight shadow effect */
}



.navbar {
  background-color: #4CAF50;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
  position: fixed;
  width: 100%;
  opacity: 30%;
  top: 0;
}

h1 {
  font-size: 3rem;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 150px;
}

.project-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 30%;
  margin: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

footer {
  
  bottom: 0;
  left: 0;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

.footer-info p {
  margin: 5px;
}


footer p {
  margin: 0;
  font-size: 14px; /* Adjust font size */
}


body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  /*background-color: rgba(199, 138, 102, 0.25); /* Light brown extracted from the background */
  /*background:linear-gradient(135deg, rgba(214, 125, 70, 0.8), rgba(58, 142, 141, 0.8)); /* Orange to Teal gradient with opacity */
  /*background-blend-mode: multiply; /* To darken the gradient when overlapped */
}

/* Scroll Icon Styling */
.scroll-icon {
  position: absolute;
  top: 550px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  color: black; /* Orange from the fire effect */
  cursor: pointer;
  animation: bounce 1s infinite;
  z-index: 1000;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}






